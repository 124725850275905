import {APP_BOOTSTRAP_LISTENER, APP_INITIALIZER, ComponentRef, ErrorHandler, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from "@angular/common/http";
import {AuthenticationInterceptor} from "./interceptors/auth.interceptor";
import {Router} from "@angular/router";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {ToastrModule} from "ngx-toastr";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";

import {RegInterceptor} from "./interceptors/reg.interceptor";
import {
  IGoogleAnalyticsSettings,
  NGX_GOOGLE_ANALYTICS_SETTINGS_TOKEN,
  NgxGoogleAnalyticsModule
} from "ngx-google-analytics";
import {environment} from "../environments/environment";
import {TrackingHelperService} from "./services/tracking-helper.service";

import * as Sentry from "@sentry/angular";


// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}




@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-top-center',
      closeButton: true,
      preventDuplicates: true,
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    // TawkMessengerAngularModule,
    NgxGoogleAnalyticsModule
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: AuthenticationInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass:RegInterceptor, multi: true},
    {
      provide: NGX_GOOGLE_ANALYTICS_SETTINGS_TOKEN,
      useValue: {
        trackingCode: environment.GA_TRACKING
      } as IGoogleAnalyticsSettings
    },
    {
      provide: APP_BOOTSTRAP_LISTENER,
      multi: true,
      useFactory: (analyticsService: TrackingHelperService) => {
        return (component: ComponentRef<any>) => {
          analyticsService.setAppComponentRef(component);
          analyticsService.initializeGoogleAnalytics();
        };
      },
      deps: [TrackingHelperService],
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },

  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
