import {environment as rivalEnvironment} from "./environment.rival";

export const environment = {
  ...rivalEnvironment,

  rivalName: "play2win",
  casinoId: 101,
  GA_TRACKING:'G-SFVYGW8PPZ',


  rivalChatGroupName: "Play2Win",
  prettyName: "Play 2 Win",
  contactEmail: "support@play2wincasinos.com",
  docsEmail: "documents@play2wincasinos.email",


  //SEO
  metaDescription: "Join Play 2 Win Casino for top online casino gaming with slots, table games, and live dealers. Secure payment, fast withdrawal, huge payouts and big real cash bonuses with welcome cash bonus. Play now and spin the wheel for big wins and payouts!",
  logoName: "play2win-online-casino-logo.png",
  logoAltText: "Play 2 Win Casino online logo with bold silver text and a red dice icon appealing to slots players and casino gaming enthusiasts."
};


