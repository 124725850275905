//this is for master.rival-new.pages.dev (currently the testBrand, version 0 the stake design)

export const environment = {
  production: true,
  games: [],

  platform: "unknown", //see isRivalCasino() in configService
  isAffiliated: false,
  isXmasOnly: false,
  isLandingsOnly: false,
  assetsPath: undefined,
  apiUrl:undefined,

  rivalName: "casinolust",
  casinoId: 70,
  prettyName: "TestBrand", //as we call it
  contactEmail: "support@test.casino",
  docsEmail: "finance@test.casino",
  GA_TRACKING: "",

  rivalChatGroupName: "Casino Lust",
  chatSystem: 'other', // see chatSystem: in configService, chatserver in chatService (default chat server on Rival: 'chat-v3.casinocontroller.com')
  tawk_propertyId: undefined,
  tawk_widgetId: undefined,
  tawk_apiKey: undefined,

  publicPromotions: [
    {id: 1, name: "400", state: "eligible", details: "welcome", imgPath: "assets/icons/welcome_promo.png"},
    {id: 2, name: "cashback", state: "eligible", details: "welcome", imgPath: "assets/icons/cashback_promo.png"},
    {id: 3, name: "raffle", state: "eligible", details: "weekly", imgPath: "assets/icons/raffle_promo.png"},
    {id: 4, name: "cashtravaganza", state: "eligible", details: "weekly", imgPath: "assets/icons/cashtra_promo.png"},
    {id: 5, name: "comppoints", state: "eligible", details: "weekly", imgPath: "assets/icons/comppoint_promo.png"},
    {id: 6, name: "tournaments", state: "eligible", details: "weekly", imgPath: "assets/icons/tournament_promo.png"},
  ],

  //remove aff game for not logged in users without the tracker
  affiliatedGames: [
    // {name: "nodepositBonus", regex:  /^(36278|36039|35689|35209).*/, gameId: 2517},
    {name: "nodepositBonus", regex:  /(36278|36039|35689|35209).*/}, //gameId2517
    {name: "nodepositKings", regex:  /(36278|36039|35689|35209).*/}, //gameId 2594  TODO have the trackers
    {name: "chipy", regex:  /(35158|36288|35052).*/}
  ],


  legalData: {
    withdrawalTime: null,
    affTerms: false
  },
  license: undefined,
  licenseNo: "#8048/JAZ",


  //SEO
  metaDescription: "Play premier gaming at Online Casino with slots (slot machine), live dealers, bingo, jackpot games. Real deposit bonuses, VIP loyalty program, secure play, trusted affiliates and fair gaming for cash wins and big money prizes!",
  logoName: "logo.png",
  logoAltText: "Test logo, made for testing lobbyless sites on Casino Lust config.",





};


